import { Col, Row } from "react-bootstrap";


function Press() {

    return (

        <section className="live" id="live">

            <Row className="section-intro">
                <Col className="col-twelve">
                    <h5>PRESS</h5>
                </Col>
            </Row>
            <Row>
                <Col className="col-twelve">
                    <div className="block-1-3 block-mob-full stack" style={{ position: 'relative' }}>
                        
                        <h6>Live Reviews</h6>
                        
                        <blockquote>
                            "Ethereal tones, a tight rhythm section and a lone, leading guitar were well forged together to make their signature sound such an astonishing appetiser. "
                            <a href="https://recordweekly.com/2022/10/17/the-amazons-leeds-beckett-su/" target="_blank" rel="noopener noreferrer">
                                <cite>
                                    {"IGoulding, Record Weekly (Live Review - The Amazons)"}
                                </cite>
                            </a>
                        </blockquote>

                        
                        <blockquote>
                            "A sonically vibrant, up-and-coming Leeds-based alt-rock pop band offered an ominous yet slick, melodic performance which after a few songs gave us a sound I would describe as similar to the likes of Pale Waves or Nothing But Thieves with a combination of melodic vocals and catchy synth/guitar lines that definitely succeeded in getting people warmed up for the main event."
                            <a href="https://www.thegryphon.co.uk/2022/10/21/the-amazons-deliver-electrifying-set-at-leeds-beckett-students-union/" target="_blank" rel="noopener noreferrer">
                                <cite>
                                    {"George Bolger, The Gryphon (Live Review - The Amazons)"}
                                </cite>
                            </a>
                        </blockquote>

                        <br/>
                        <h6>Manic</h6>
                        <blockquote>“The song also harnesses the stadium thrills of U2, and the darker, more electronic material of acts like <strong>PVRIS.</strong> That fusion makes Manic both electrifying and energising, even on the first listen.”
                            <a href="https://www.analoguetrash.com/blog/ava-in-the-dark-manic" target="_blank" rel="noopener noreferrer">
                                <cite>
                                    Analog Trash
                                </cite>
                            </a>
                        </blockquote>

                        <blockquote>“Builds effortlessly into a captivating chorus that is perfectly explosive”
                            <a href="https://www.chalkpitrecords.com/new-music/ava-in-the-dark-manic" target="_blank" rel="noopener noreferrer">
                                <cite>
                                    Chalkpit Records
                                </cite>
                            </a>
                        </blockquote>

                        <blockquote>“A mighty pop-rock gem with industrial elements and a powerhouse vocal performance from lead singer Kiera, singing poignant lyrics about “generational trauma”. Massive!”
                            <a href="https://lastdaydeaf.com/fresh-n-green-january-16th/" target="_blank" rel="noopener noreferrer">
                                <cite>
                                    Last Day Deaf
                                </cite>
                            </a>
                        </blockquote>

                        <blockquote>“But make no mistake, for all it’s pop sensibility this is a rock song; it’s heavy, It’s just not done with those standard guitar sounds.”
                            <a href="http://localsoundfocus.com/single-review-ava-in-the-dark-manic/" target="_blank" rel="noopener noreferrer">
                                <cite>Local Sound Focus</cite>
                            </a>
                        </blockquote>

                        <blockquote>“Ava in the Dark will set a new industry standard if they continue in this vein.”
                            <a href="https://lefuturewave.com/2022/01/07/introducing-ava-in-the-dark-manic/" target="_blank" rel="noopener noreferrer">
                                <cite>
                                    lefuturewave
                                </cite>
                            </a>
                        </blockquote>


                        <br/>
                        <h6>Delete Us Forever</h6>

                        {/* delete us forever*/}

                        <blockquote>“The EP received massive attention from BBC Radio 6 Tom Robinson’s Mixtape, BBC Radio Wales including ‘DELETE US FOREVER’ in their A-list for 2 weeks after many plays on Adam Walton’s show, XS Manchester’s Sophie Sveinsson and the Evening Playlist, Amazing Radio’s Shell Zenner, Charlie Ashcroft and Frankie Francis, and continuous airplay from Student Radio Network, including ‘DELETE US FOREVER’ on their ‘Notebooks n Chill’ playlist.”
                            <a href="https://www.launchpad-music.com/supported-artists/ava-in-the-dark" target="_blank" rel="noopener noreferrer">
                                <cite>
                                    Launchpad
                                </cite>
                            </a>
                        </blockquote>

                        <blockquote>“Her voice is strong, switching from reserved verses to the soaring chorus with flawless ease.”
                            <a href="https://istoodupandisaidyeah.music.blog/2020/09/25/track-review-ava-in-the-dark-delete-us-forever/" target="_blank" rel="noopener noreferrer">
                                <cite>
                                    I Said Yeah
                                </cite>
                            </a>
                        </blockquote>

                        <blockquote>“‘Delete Us Forever’, that’s the one thing you won’t be doing anytime soon.”
                            <a href="https://moggblog.com/2020/09/28/moggblogs-monday-magic-22/" target="_blank" rel="noopener noreferrer">
                                <cite>
                                    MoggBlogg
                                </cite>
                            </a>
                        </blockquote>

                        <blockquote>“It has that 80s’ pop gloss feel. It soars to die for. And I’m saying 80s’ pop feel because while it has that old-school thing, it also has that ‘of now’ sound. It’s taken the really good bits of 80s’ pop and melded them with the best of now.”
                            <a href="http://localsoundfocus.com/single-review-ava-in-the-dark-delete-us-forever/" target="_blank" rel="noopener noreferrer">
                                <cite>
                                    Local Sound Focus
                                </cite>
                            </a>
                        </blockquote>

                        <blockquote>“Treading somewhere between the likes of Wolf Alice and Pale Waves, this indie track of existentialism pairs classic dripping synths with a driving bass and angelic, breathy vocals.”
                            <a href="https://www.chalkpitrecords.com/new-blog/09/2/track-of-the-day-ava-in-the-dark-have-their-sights-set-on-getting-out-of-the-dark-and-onto-the-big-bright-stage" target="_blank" rel="noopener noreferrer">
                                <cite>
                                    Chalkpit
                                </cite>
                            </a>
                        </blockquote>

                        <blockquote>“Yorkshire group layer on some shiny, shimmery synths and go full ‘film montage’ over four minutes of upbeat, though slightly melancholic retro pop perfection.”
                            <a href="https://www.analoguetrash.com/blog/ava-in-the-dark-delete-us-forever" target="_blank" rel="noopener noreferrer">
                                <cite>
                                    Analogue Trash
                                </cite>
                            </a>
                        </blockquote>

                        <blockquote>
                            <a href="https://popmuzik.se/108259/ava-in-the-dark-delete-us-forever/" target="_blank" rel="noopener noreferrer">
                                <cite>
                                    Popmuzik
                                </cite>
                            </a>
                        </blockquote>

                        <blockquote>“A sophisticated and compelling track of coherent melancholic music, juxtaposed with emotional lyricism and a sound that is reminiscent of classic 80s electro-pop with a rebellious indie edge.”
                            <a href="https://spotlightuk.co.uk/articles/2020/10/4/single-review-delete-us-forever-by-ava-in-the-dark" target="_blank" rel="noopener noreferrer">
                                <cite>
                                    Spotlight
                                </cite>
                            </a>
                        </blockquote>

                        <blockquote>“One word: WOW! If your blood doesn’t boil here, fire your doctor, change your meds and come back. Don’t delete this band. Save them right now, like them, love them, embrace them, share them with your friends. Listen to Ava In The Dark on repeat in your bedroom, in your car, in your favorite pub, just everywhere, and you’ll smile all day long.”
                            <a href="https://turnupthevolume.blog/2020/10/06/save-them-immediately-flabbergasting-pop-with-ava-in-the-dark-and-new-single-delete-us-forever/" target="_blank" rel="noopener noreferrer">
                                <cite>
                                    Turn up the volume
                                </cite>
                            </a>
                        </blockquote>

                        <blockquote>
                            <a href="https://glassefactory.com/tunesday-top-30-8-september-2020/" target="_blank" rel="noopener noreferrer">
                                <cite>
                                    Glasse Factory Playlist
                                </cite>
                            </a>
                        </blockquote>

                        <blockquote>
                            <a href="https://whenthehornblows.com/content/2020/10/9/weekly-top-tracks-98" target="_blank" rel="noopener noreferrer">
                                <cite>
                                    When The Horn Blows Weekly Top Tracks #98
                                </cite>
                            </a>
                        </blockquote>

                        <blockquote>“Their dance-style bass and reverberating drums create an atmospheric indie track is a haunting, yet bracing, rendition of 1980s pop.”
                            <a href="https://www.indiegroovemusicblog.co.uk/reviews/ava-in-the-dark-delete-us-forever" target="_blank" rel="noopener noreferrer">
                                <cite>
                                    Indie Groove Music Blog
                                </cite>
                            </a>
                        </blockquote>

                        <blockquote>
                            <a href="https://comeherefloyd.com/ava-in-the-dark-delete-us-forever/" target="_blank" rel="noopener noreferrer">
                                <cite>
                                    Come Here Floyd
                                </cite>
                            </a>
                        </blockquote>

                        <blockquote>
                            <a href="http://www.chordblossom.com/blogs/no-fixed-abode-5" target="_blank" rel="noopener noreferrer"><cite>
                                Chord Blossom</cite>
                            </a>
                        </blockquote>

                    </div>
                </Col>
            </Row>
        </section>
    );
}

export default Press;