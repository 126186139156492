import Intro from "../components/home/intro-section";
import MailingList
    from "../components/home/mailinglist-section";
// import { useIsAuthenticated } from '@azure/msal-react';
import Footer from "../components/footer";
import React from "react";
// import Live from "../components/home/live-section";

function Home() {

    // const isAuthenticated = useIsAuthenticated();


    return (
        <div className="home">
            <Intro />
            {/* <Live /> */}
            <MailingList />
            <Footer />
        </div>
    );
}

export default Home;
