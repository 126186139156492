import React from "react";
import { Col, Row } from "react-bootstrap";

function Radio() {

    return (

        <section className="live-alt" id="live-alt">

            <Row className="section-intro">
                <Col className="col-twelve">
                    <h5>RADIO</h5>
                </Col>
            </Row>
            <Row >
                <Col className="col-twelve">
                    <div className="block-1-3 block-mob-full stack" id="folio-wrapper" style={{ position: 'relative' }}>

                        <blockquote>
                            <p>“..”</p>
                            <a href="https://www.bbc.co.uk/programmes/m001kts3" target="_blank" rel="noopener noreferrer">
                                <cite>Alyx Holcombe BBC Radio 1, Die 4 You</cite>
                            </a>
                        </blockquote>

                        <blockquote>
                            <p>"I think this is their strongest track to date you know"</p>
                            <a href="https://www.bbc.co.uk/programmes/p0bj8w54" target="_blank" rel="noopener noreferrer">
                                <cite>Emily Pilbeam BBC Introducing, Manic (Live Sessions)</cite>
                            </a>
                        </blockquote>

                        <blockquote>
                            <p>“Such a slick sound on this, if you like Pale Waves, you'll like this! These would be perfect to see at a festival”</p>
                            {/* <a href=""> */}
                                <cite>Emily Pilbeam BBC Introducing, Delete Us Forever</cite>
                            {/* </a> */}
                        </blockquote>

                        <blockquote>
                            <p>“Ther's something deliciously old school about this”</p>
                            <cite>Adam Walton, BBC Radio Wales, Delete Us Forever</cite>
                        </blockquote>

                    </div>
                </Col>
            </Row>
        </section>
    );
}

export default Radio;