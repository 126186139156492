import Footer from '../components/footer';
// import Live from '../components/home/live-section';
import Bio from '../components/OneSheet/bio';
// import LiveVideo from '../components/OneSheet/live-video';
import Press from '../components/OneSheet/press';
import Radio from '../components/OneSheet/radio';
import SocialStats from '../components/OneSheet/social-stats';
import StreamingStats from '../components/OneSheet/streaming-stats';
// import { tempGigs } from '../components/home/live-section';
import PastGigsSection from '../components/OneSheet/pastGigs-section';
import OneSheetIntro from '../components/OneSheet/OneSheetIntro-section';
import { ParallaxBanner } from 'react-scroll-parallax';
import React from 'react';

function OneSheet() {

    return (
        <div className="home">
            <OneSheetIntro />

            <Bio />

            {/* <LiveVideo /> */}

            <Radio />

            <section className='live' id='live' style={{padding:0}} >
                <ParallaxBanner
                    layers={[
                        { image: './img/IMG_5895.JPG', speed: -20 }
                    ]}
                     className="aspect-[2/1]"
                     style={{height:'500px'}}
                />
            </section>

            <Press />

            <ParallaxBanner
                    layers={[
                        { image: './img/IMG_5901.JPG', speed: -20 }
                    ]}
                     className="aspect-[2/1]"
                     style={{height:'500px'}}
                />

            {/* If there's any upcoming gigs there'll be a section to show them */}
            {/* {(tempGigs.length > 0 &&
                <Live />
            )} */}

            <PastGigsSection />
            <ParallaxBanner
                    layers={[
                        { image: './img/hpbccrowd.JPG', speed: -30,  }
                    ]}
                     className="aspect-[2/1]"
                     style={{height:'1100px'}}
                />

            <StreamingStats />



            <SocialStats />

            <section>
                {/* live picture here */}
            </section>

            <Footer />
        </div>
    );
}

export default OneSheet;