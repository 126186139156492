import { useEffect, useState } from 'react';
import { getSpotifyToken } from '../../services/spotifyService';
import { Col, Row } from "react-bootstrap";

function StreamingStats() {




    return (

        <section className="live" id="live">

            <Row className="section-intro">
                <Col className="col-twelve">
                    <h5>STREAMING</h5>
                    <h3>EDITORIAL PLAYLISTS</h3>
                </Col>
            </Row>
            <Row className="portfolio-content">
                <Col className="col-twelve" style={{ display: 'flex', justifyContent: "center" }}>
                    <div className="block-1-3 block-mob-full stack"
                        id="folio-wrapper"
                    >

                        <div className="bgrid folio-item">
                            <div className="item-wrap">
                                <h4>Apple Music:<br/>  New in Alternative</h4>
                                <img src="https://is1-ssl.mzstatic.com/image/thumb/Video114/v4/bf/78/af/bf78af29-2c21-5954-1607-c08e91ed82d1/Jobde0405dc-0d47-43b8-aae7-0dacc5519991-110098423-PreviewImage_preview_image_nonvideo_sdr-Time1615975926020.png/305x305cc.webp"
                                    alt="playlist"
                                    width="100%"/>
                            </div>
                        </div>

                        <div className="bgrid folio-item">
                            <div className="item-wrap">
                                <h4>Apple Music:<br/>  New in Indie</h4>
                                <img src="https://is1-ssl.mzstatic.com/image/thumb/Video114/v4/a4/68/85/a468851d-f7df-fc32-90d5-c9708d18056f/Joba52760cd-7136-4cd9-9594-c344a90c8064-110895690-PreviewImage_preview_image_nonvideo_sdr-Time1617810178139.png/305x305cc.webp"
                                    alt="playlist"
                                    width="100%"/>
                            </div>
                        </div>

                        <div className="bgrid folio-item">
                            <div className="item-wrap">
                                <h4>Amazon Music: <br/> Notebooks & Chill</h4>
                                <img src="../img/onesheet/amazon music notebooks playlist.jpg"
                                    alt="playlist"
                                    height="305px"
                                    width="100%" />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            {/* <Row className="portfolio-content">
                <Col className="col-twelve">
                    <div className="block-1-3 block-mob-full stack" id="folio-wrapper" style={{ position: 'relative' }}>
                        <h3>Streaming Stats</h3>

                    </div>
                </Col>
            </Row> */}
        </section>
    );
}

export default StreamingStats;