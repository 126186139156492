import { Configuration, LogLevel } from '@azure/msal-browser';

const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_SignUp_SignIn",
  },
  authorities: {
    signUpSignIn: {
      authority: "https://AvaInTheDark.b2clogin.com/AvaInTheDark.onmicrosoft.com/B2C_1_SignUp_SignIn",
    },
  },
  authorityDomain: "AvaInTheDark.b2clogin.com",
};

export const msalConfig: Configuration = {
  auth: {
    clientId: "937679aa-2365-4eac-9583-ad9c9bf21a50",
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: '/',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
                return;
            }
            switch (level) {
                case LogLevel.Error:
                    // console.error(message);
                    return;
                case LogLevel.Info:
                    // console.info(message);
                    return;
                case LogLevel.Verbose:
                    // console.debug(message);
                    return;
                case LogLevel.Warning:
                    // console.warn(message);
                    return;
                default:
                    return;
            }
      },
      logLevel: LogLevel.Verbose, // Set to Verbose for detailed logging
      piiLoggingEnabled: true,
    },
  },
};


export const loginRequest = {
  scopes: [
    "https://AvaInTheDark.onmicrosoft.com/937679aa-2365-4eac-9583-ad9c9bf21a50/Files.Read",
    "openid",
    "profile",
    "offline_access",
  ],
};