import { Col, Row } from "react-bootstrap";

function Bio() {

    return (

        <section className="live" id="live">

            <Row className="section-intro">
                <Col className="col-twelve">
                    <h5>BIO</h5>
                </Col>
            </Row>
            <Row className="portfolio-content">
                <Col className="col-twelve">
                    <div className="block-1-3 block-mob-full stack" id="folio-wrapper" style={{ position: 'relative'}}>
                        <strong style={{ color: "hsl(350deg 100% 38.1%)" }}>Ava in the Dark</strong>, an Alternative Rock and Dream-Gaze band from Leeds, UK, led by the Welsh dreamy vocalist and producer Kiera Bickerstaff. —Tommie James on guitar, Izzy Hobbs on bass, and Emma Liu on drums—embraces influences, blending modern rock, shoegaze alt-rock with manipulated guitar ambiences, and danceable beats.
                        <br/>
                        <br/>
                        Ava in the Dark finds inspiration from <strong style={{ color: "hsl(350deg 100% 38.1%)" }}>Wolf Alice, Nothing But Thieves, Billie Eilish, Yonaka and Softcult.</strong>
                        <br/>
                        <br/>
                        Fresh off a <strong style={{ color: "hsl(350deg 100% 38.1%)" }}>UK Tour with Spyres</strong>, Ava in the Dark wrapped up 2023 supporting Lucia & The Best Boys, setting the stage for an exhilarating 2024 including recent gigs with Saint Agnes, Ciel at Oporto, Silver Moth (with Stuart of Mogwai) and headlining Polar Bear Music Club's <strong style={{ color: "hsl(350deg 100% 38.1%)" }}>The Sesh </strong>and co-headling Hyde Park Book Club with O'Phantom. Anticipate a wave of new music releases, spotlighting a project crafted at Marshall Amplification Studios and their home studio in Leeds.
                        <br/>
                        <br/>
                        Their high-energy rock anthem 'Manic,' was released on September 8th with <strong style={{ color: "hsl(350deg 100% 38.1%)" }}> LAB Records. </strong>'Manic' won <a href="https://youthmusic.org.uk/original-track-award-group-22"> <strong style={{ color: "hsl(350deg 100% 38.1%)" }}>Youth Music's "Best Original Track Award (Group),"</strong></a> sponsored by Marshall Amplification, after supporting The Amazons on their UK Tour.
                        <br/>
                        <br/>
                        In 2023, Ava in the Dark unveiled 'Die 4 You,' a Shoegaze Dreampop-Rock anthem, gaining support from <a href="https://www.bbc.co.uk/programmes/m001kts3"> <strong style={{ color: "hsl(350deg 100% 38.1%)" }}>BBC Radio 1's Alyx Wycombe’s Introducing Rock Show.</strong></a>
                        <br/>
                        <br/>
                        Previously featured on BBC Radio 1, BBC Radio 6, BBC Radio Wales A-List, XS Manchester Live in Session & ‘Ones to Watch,’ BBC Music Introducing ‘One’s to Watch,’ Live in Session & ‘Best of,’ and showcased at festivals: Live at Leeds, FOCUS Wales, Hanwell Hootie and more.

                    </div>
                </Col>
            </Row>
        </section>
    );
}

export default Bio;