import React from "react";

import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faYoutube, faSpotify, faItunesNote, faBandcamp, faTiktok } from "@fortawesome/free-brands-svg-icons";

function Footer() {
    return (
        <footer>
            <Row>
                <Col sm={8} className="col-tbree tab-full pull-right social">
                    <ul className="footer-social">
                        <li>
                            <a href="https://www.instagram.com/avainthedark/">
                                <FontAwesomeIcon icon={faInstagram} size="lg" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/channel/UCo-bPwCSh3jHZYl_W3i0t3A">
                                <FontAwesomeIcon icon={faYoutube} size="lg" />
                            </a>
                        </li>
                        <li>
                            <a href="https://open.spotify.com/artist/5UFq82PvmEauTLHRqGJIFv?si=AEvAfv0zQFGyIFu5T8aJUw">
                                <FontAwesomeIcon icon={faSpotify} size="lg" />
                            </a>
                        </li>
                        <li>
                            <a href="https://music.apple.com/gb/artist/ava-in-the-dark/1450407649">
                                <FontAwesomeIcon icon={faItunesNote} size="lg" />
                            </a>
                        </li>
                        <li>
                            <a href="https://avainthedark.bandcamp.com/?ltclid=e4ba4206-b697-42f2-8fb3-8b8733466afd">
                                <FontAwesomeIcon icon={faBandcamp} size="lg" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.tiktok.com/@avainthedark">
                                <FontAwesomeIcon icon={faTiktok} size="lg" />
                            </a>
                        </li>
                    </ul>
                </Col>

                <Col sm={8} className="col-four pull-right tab-full">
                    <div className="copyright">
                        <span>Distributed by 
                            <a href="https://labrecs.com/"> LAB Records</a></span>
                    </div>
                </Col>
            </Row>
        </footer>);
}

export default Footer;
