export const getSpotifyToken = async (): Promise<string | null> => {
    try {
      // Assuming your Azure function is deployed under /api/GetSpotifyToken
      const response = await fetch('http://localhost:7071/api/GetSpotifyToken');
      
      if (!response.ok) {
        throw new Error(`Error fetching token: ${response.statusText}`);
      }
  
      const data = await response.json();
      return data.token;
    } catch (error) {
      console.error('Failed to fetch Spotify token', error);
      return null;
    }
  };
  